import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import Table from '../components/Table';
import { toast } from 'react-toastify';
import OrderAddress from '../components/OrderAddress';
import OrderItem from '../components/OrderItem';
import AnalyticsLineChart from '../components/AnalyticsLineChart';
import OrderEquipment from '../components/OrderEquipment';
import OrderEmployeeSearch from '../components/OrderEmployeeSearch';
import OrderScheduledDateTime from '../components/OrderScheduledDateTime';
import { colors } from '../theme';
import DynamicForm from '../components/DynamicForm';
import { api } from '../utils';
import { translate } from '../utils/localization-helper';
import Modal from 'react-bootstrap/Modal';
import '../App.css';


function AnalyticsFacebookPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings'))) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleActionButtonClick = (destination) => {
    navigate(destination);
  };

  return (
    <Menu>

    </Menu>
  );
}


function AnalyticsGooglePage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !config.permissions.includes('analytics')) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleActionButtonClick = (destination) => {
    navigate(destination);
  };

  return (
    <Menu>

    </Menu>
  );
}


function AnalyticsCommonPage() {

  const navigate = useNavigate();
  const { config, isAuthenticated } = useAuth();
  const [filterData, setFilterData] = useState();
  const [initialFormData, setInitialFormData] = useState();

  useEffect(() => {
    if (isAuthenticated == false) navigate('/login');
    if (config && config.permissions && !(config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings'))) navigate('/');

    let date = new Date();
    setInitialFormData(getFirstDays());

  }, [isAuthenticated, navigate]);

  const formConfig = {
    'fields': [
      {
        'title': translate('AnalyticsCommonPage.form.month'),
        'key': 'dt1',
        'type': 'datetime',
        'required': true,
        'options': Array.from({ length: 12 }, (_, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }))
      },
      {
        'title': translate('AnalyticsCommonPage.form.year'),
        'key': 'dt2',
        'type': 'datetime',
        'required': true,
        'options': Array.from({ length: 6 }, (_, i) => ({ value: (2024 + i).toString(), label: (2024 + i).toString() }))
      }
    ]
  };

  const tableEmployeesConfig = {
    'fields': [
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_name'),
        'key': 'employee__full_name',
      },
      {
        'title': translate('EmployeesPage.table.fields.position'),
        'key': 'employee__position',
        'type': 'select',
        'options': [
          { value: 1, label: translate('EmployeesPage.table.fields.cleaner') },
          { value: 2, label: translate('EmployeesPage.table.fields.cleaner_manager') },
          { value: 3, label: translate('EmployeesPage.table.fields.sales_manager') },
          { value: 4, label: translate('EmployeesPage.table.fields.office_manager') },
          { value: 5, label: translate('EmployeesPage.table.fields.quality_assurance') },
        ],
      },
    ],
  };

  const tableConfig = {
    'fields': [
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_name'),
        'key': 'campaign_name',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_cost_total'),
        'type': 'number',
        'key': 'campaign_cost_total',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_count'),
        'type': 'number',
        'key': 'orders_count',
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed'),
        'type': 'number',
        'key': 'orders_is_completed',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_sum'),
        'type': 'number',
        'key': 'orders_is_completed_sum',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_percent'),
        'type': 'number',
        'key': 'orders_is_completed_percent',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_completed_cost_lead',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_roas'),
        'type': 'number',
        'key': 'orders_is_completed_roas',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_roas_clean'),
        'type': 'number',
        'key': 'orders_is_completed_roas_clean',
        'color': '#06ff0075'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed'),
        'type': 'number',
        'key': 'orders_is_confirmed',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_sum'),
        'type': 'number',
        'key': 'orders_is_confirmed_sum',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_percent'),
        'type': 'number',
        'key': 'orders_is_confirmed_percent',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_confirmed_cost_lead',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_roas'),
        'type': 'number',
        'key': 'orders_is_confirmed_roas',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_roas_clean'),
        'type': 'number',
        'key': 'orders_is_confirmed_roas_clean',
        'color': '#ffb70075'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_sum'),
        'type': 'number',
        'key': 'orders_is_failed_sum',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed'),
        'type': 'number',
        'key': 'orders_is_failed',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_percent'),
        'type': 'number',
        'key': 'orders_is_failed_percent',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_failed_cost_lead',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_roas'),
        'type': 'number',
        'key': 'orders_is_failed_roas',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_roas_clean'),
        'type': 'number',
        'key': 'orders_is_failed_roas_clean',
        'color': '#d8000075'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_canceled'),
        'type': 'number',
        'key': 'orders_is_canceled',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_canceled_percent'),
        'type': 'number',
        'key': 'orders_is_canceled_percent',
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_others'),
        'type': 'number',
        'key': 'orders_others',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_others_percent'),
        'type': 'number',
        'key': 'orders_others_percent',
      },
    ],
  };

  const tableWeeksGoogleCampaingsConfig = {
    'heatmap': [
      {
        'field_keys': [
          'days_hm_orders_monday',
          'days_hm_orders_tuesday',
          'days_hm_orders_wednesday',
          'days_hm_orders_thursday',
          'days_hm_orders_friday',
          'days_hm_orders_saturday',
          'days_hm_orders_sunday'
        ],
        'color': '#ff0000'
      },
      {
        'field_keys': [
          'hours_hm_orders_part0',
          'hours_hm_orders_part1',
          'hours_hm_orders_part2',
          'hours_hm_orders_part3',
          'hours_hm_orders_part4',
          'hours_hm_orders_part5',
          'hours_hm_orders_part6',
          'hours_hm_orders_part7',
          'hours_hm_orders_part8',
          'hours_hm_orders_part9',
          'hours_hm_orders_part10',
          'hours_hm_orders_part11',
          'hours_hm_orders_part12',
          'hours_hm_orders_part13',
          'hours_hm_orders_part14',
          'hours_hm_orders_part15',
          'hours_hm_orders_part16',
          'hours_hm_orders_part17',
          'hours_hm_orders_part18',
          'hours_hm_orders_part19',
          'hours_hm_orders_part20',
          'hours_hm_orders_part21',
          'hours_hm_orders_part22',
          'hours_hm_orders_part23'
        ],
        'color': '#327da8'
      },
      {
        'field_keys': [
          'hours_hm_gads_views_part0',
          'hours_hm_gads_views_part1',
          'hours_hm_gads_views_part2',
          'hours_hm_gads_views_part3',
          'hours_hm_gads_views_part4',
          'hours_hm_gads_views_part5',
          'hours_hm_gads_views_part6',
          'hours_hm_gads_views_part7',
          'hours_hm_gads_views_part8',
          'hours_hm_gads_views_part9',
          'hours_hm_gads_views_part10',
          'hours_hm_gads_views_part11',
          'hours_hm_gads_views_part12',
          'hours_hm_gads_views_part13',
          'hours_hm_gads_views_part14',
          'hours_hm_gads_views_part15',
          'hours_hm_gads_views_part16',
          'hours_hm_gads_views_part17',
          'hours_hm_gads_views_part18',
          'hours_hm_gads_views_part19',
          'hours_hm_gads_views_part20',
          'hours_hm_gads_views_part21',
          'hours_hm_gads_views_part22',
          'hours_hm_gads_views_part23'
        ],
        'color': '#3b00ba'
      }
    ],
    'fields': [
      {
        'title': translate('AnalyticsCommonPage.table.fields.period'),
        'key': 'period',
        'color': '#0071ff75'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_cost_total'),
        'type': 'number',
        'key': 'campaign_cost_total',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_day_avg_cost'),
        'type': 'number',
        'key': 'campaign_day_avg_cost',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_impressions_total'),
        'type': 'number',
        'key': 'campaign_impressions_total',
        'color': '#ff880075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.campaign_clicks_total'),
        'type': 'number',
        'key': 'campaign_clicks_total',
        'color': '#ff880075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.click_cost'),
        'type': 'number',
        'key': 'click_cost',
        'color': '#ff880075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.lead_cost'),
        'type': 'number',
        'key': 'lead_cost',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.leads_count'),
        'type': 'number',
        'key': 'orders_count',
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.сonversion_rate_view'),
        'type': 'percent',
        'key': 'сonversion_rate_view',
        'color': '#ff007175'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.сonversion_rate_lead'),
        'type': 'percent',
        'key': 'сonversion_rate_lead',
        'color': '#ff007175'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.сonversion_rate_order'),
        'type': 'percent',
        'key': 'сonversion_rate_order',
        'color': '#ff007175'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed'),
        'type': 'number',
        'key': 'orders_is_completed',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_completed_cost_lead',
        'color': '#06ff0075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_completed_sum'),
        'type': 'number',
        'key': 'orders_is_completed_sum',
        'color': '#06ff0075'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed'),
        'type': 'number',
        'key': 'orders_is_confirmed',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_confirmed_cost_lead',
        'color': '#ffb70075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_confirmed_sum'),
        'type': 'number',
        'key': 'orders_is_confirmed_sum',
        'color': '#ffb70075'
      },

      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed'),
        'type': 'number',
        'key': 'orders_is_failed',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_cost_lead'),
        'type': 'number',
        'key': 'orders_is_failed_cost_lead',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.orders_is_failed_sum'),
        'type': 'number',
        'key': 'orders_is_failed_sum',
        'color': '#d8000075'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.days.monday'),
        'type': 'number',
        'key': 'days_hm_orders_monday'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.days.tuesday'),
        'type': 'number',
        'key': 'days_hm_orders_tuesday'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.days.wednesday'),
        'type': 'number',
        'key': 'days_hm_orders_wednesday'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.days.thursday'),
        'type': 'number',
        'key': 'days_hm_orders_thursday'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.days.friday'),
        'type': 'number',
        'key': 'days_hm_orders_friday'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.days.saturday'),
        'type': 'number',
        'key': 'days_hm_orders_saturday'
      },
      {
        'title': translate('AnalyticsCommonPage.table.fields.days.sunday'),
        'type': 'number',
        'key': 'days_hm_orders_sunday'
      },

      { 'title': '0h', 'type': 'number', 'key': 'hours_hm_orders_part0' },
      { 'title': '1h', 'type': 'number', 'key': 'hours_hm_orders_part1' },
      { 'title': '2h', 'type': 'number', 'key': 'hours_hm_orders_part2' },
      { 'title': '3h', 'type': 'number', 'key': 'hours_hm_orders_part3' },
      { 'title': '4h', 'type': 'number', 'key': 'hours_hm_orders_part4' },
      { 'title': '5h', 'type': 'number', 'key': 'hours_hm_orders_part5' },
      { 'title': '6h', 'type': 'number', 'key': 'hours_hm_orders_part6' },
      { 'title': '7h', 'type': 'number', 'key': 'hours_hm_orders_part7' },
      { 'title': '8h', 'type': 'number', 'key': 'hours_hm_orders_part8' },
      { 'title': '9h', 'type': 'number', 'key': 'hours_hm_orders_part9' },
      { 'title': '10h', 'type': 'number', 'key': 'hours_hm_orders_part10' },
      { 'title': '11h', 'type': 'number', 'key': 'hours_hm_orders_part11' },
      { 'title': '12h', 'type': 'number', 'key': 'hours_hm_orders_part12' },
      { 'title': '13h', 'type': 'number', 'key': 'hours_hm_orders_part13' },
      { 'title': '14h', 'type': 'number', 'key': 'hours_hm_orders_part14' },
      { 'title': '15h', 'type': 'number', 'key': 'hours_hm_orders_part15' },
      { 'title': '16h', 'type': 'number', 'key': 'hours_hm_orders_part16' },
      { 'title': '17h', 'type': 'number', 'key': 'hours_hm_orders_part17' },
      { 'title': '18h', 'type': 'number', 'key': 'hours_hm_orders_part18' },
      { 'title': '19h', 'type': 'number', 'key': 'hours_hm_orders_part19' },
      { 'title': '20h', 'type': 'number', 'key': 'hours_hm_orders_part20' },
      { 'title': '21h', 'type': 'number', 'key': 'hours_hm_orders_part21' },
      { 'title': '22h', 'type': 'number', 'key': 'hours_hm_orders_part22' },
      { 'title': '23h', 'type': 'number', 'key': 'hours_hm_orders_part23' },

      { 'title': '0h', 'type': 'number', 'key': 'hours_hm_gads_views_part0' },
      { 'title': '1h', 'type': 'number', 'key': 'hours_hm_gads_views_part1' },
      { 'title': '2h', 'type': 'number', 'key': 'hours_hm_gads_views_part2' },
      { 'title': '3h', 'type': 'number', 'key': 'hours_hm_gads_views_part3' },
      { 'title': '4h', 'type': 'number', 'key': 'hours_hm_gads_views_part4' },
      { 'title': '5h', 'type': 'number', 'key': 'hours_hm_gads_views_part5' },
      { 'title': '6h', 'type': 'number', 'key': 'hours_hm_gads_views_part6' },
      { 'title': '7h', 'type': 'number', 'key': 'hours_hm_gads_views_part7' },
      { 'title': '8h', 'type': 'number', 'key': 'hours_hm_gads_views_part8' },
      { 'title': '9h', 'type': 'number', 'key': 'hours_hm_gads_views_part9' },
      { 'title': '10h', 'type': 'number', 'key': 'hours_hm_gads_views_part10' },
      { 'title': '11h', 'type': 'number', 'key': 'hours_hm_gads_views_part11' },
      { 'title': '12h', 'type': 'number', 'key': 'hours_hm_gads_views_part12' },
      { 'title': '13h', 'type': 'number', 'key': 'hours_hm_gads_views_part13' },
      { 'title': '14h', 'type': 'number', 'key': 'hours_hm_gads_views_part14' },
      { 'title': '15h', 'type': 'number', 'key': 'hours_hm_gads_views_part15' },
      { 'title': '16h', 'type': 'number', 'key': 'hours_hm_gads_views_part16' },
      { 'title': '17h', 'type': 'number', 'key': 'hours_hm_gads_views_part17' },
      { 'title': '18h', 'type': 'number', 'key': 'hours_hm_gads_views_part18' },
      { 'title': '19h', 'type': 'number', 'key': 'hours_hm_gads_views_part19' },
      { 'title': '20h', 'type': 'number', 'key': 'hours_hm_gads_views_part20' },
      { 'title': '21h', 'type': 'number', 'key': 'hours_hm_gads_views_part21' },
      { 'title': '22h', 'type': 'number', 'key': 'hours_hm_gads_views_part22' },
      { 'title': '23h', 'type': 'number', 'key': 'hours_hm_gads_views_part23' },
    ],
  };

  const saveCustomHandler = async (data) => {
    setFilterData(data);
  };

  const getFirstDays = () => {
      const now = new Date();

      // Get the first day of the current month at midnight
      const firstDayCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      firstDayCurrentMonth.setHours(0, 0, 0, 0); // Set to midnight

      // Get the first day of the next month at midnight
      const firstDayNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      firstDayNextMonth.setHours(0, 0, 0, 0); // Set to midnight

      return {
          dt1: firstDayCurrentMonth,
          dt2: firstDayNextMonth
      };
  };


  return (
    <Menu>
      
      <div className='col-md-3 my-3'>
          <div className='p-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <div className=''>
              <DynamicForm initialData={initialFormData} formConfig={formConfig} saveCustomHandler={saveCustomHandler} />
            </div>
          </div>

        </div>

        { filterData ? <div className='col-md-9 my-3'>

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundAnalyticsGoogleAds, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.google_campaings'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundAnalyticsGoogleAds, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.total_leads'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundAnalyticsGoogleAds, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.staff'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.states.completed'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.reviews'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundAnalyticsGoogleAds, borderRadius: '10px' }}>
            <AnalyticsLineChart method={'analytics.common.google_campaing_details'} filterData={filterData} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('analytics_google_campaings')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Table noPadding={true} initialFilters={filterData} method={'analytics.common.google_campaings_table'} tableConfig={tableConfig} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('weeks_google_campaings_table')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Table title={'Weeks total'} noPadding={true} initialFilters={{ campaign_type: [1, 2], ...filterData }} method={'analytics.common.weeks_google_campaings_table'} tableConfig={tableWeeksGoogleCampaingsConfig} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('weeks_google_campaings_table')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Table title={'Weeks total (search)'} noPadding={true} initialFilters={{ campaign_type: [1], ...filterData }} method={'analytics.common.weeks_google_campaings_table'} tableConfig={tableWeeksGoogleCampaingsConfig} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics') || config.permissions.includes('weeks_google_campaings_table')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Table title={'Weeks total (pmax)'} noPadding={true} initialFilters={{ campaign_type: [2], ...filterData }} method={'analytics.common.weeks_google_campaings_table'} tableConfig={tableWeeksGoogleCampaingsConfig} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Table noPadding={true} initialFilters={filterData} method={'analytics.common.employees.table'} tableConfig={tableEmployeesConfig} />
          </div> : null }

          {config && config.permissions && (config.permissions.includes('analytics')) ? <div className='p-3 mb-3' style={{ backgroundColor: colors.backgroundSecondary, borderRadius: '10px' }}>
            <Table noPadding={true} initialFilters={filterData} method={'analytics.common.orders.table'} tableConfig={tableEmployeesConfig} />
          </div> : null }
          
        </div> : null }
    </Menu>
  );
}


export { AnalyticsFacebookPage };
export { AnalyticsCommonPage };
export { AnalyticsGooglePage };